






import {Component, Vue} from 'vue-property-decorator';
@Component({
  components: {},
  metaInfo: {
    title: 'Blog | Digital Marketing insights | Ocere [2021!]',
  },
})
export default class extends Vue {}
