import { render, staticRenderFns } from "./Blog.vue?vue&type=template&id=06da283a&scoped=true&"
import script from "./Blog.vue?vue&type=script&lang=ts&"
export * from "./Blog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06da283a",
  null
  
)

export default component.exports